<template>
  <el-card shadow="never" style="height: 100%">
    <el-form
        id="change"
        status-icon
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
    >
      <el-form-item label="类别">
        <el-select clearable v-model="typeid" placeholder="请选择">
          <el-option
              v-for="item in typeOptions"
              :key="item.id"
              :label="item.value"
              :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="所属客户">
      <el-select v-model="customerId" placeholder="请选择">
        <el-option
            v-for="item in customerOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
        >
        </el-option>
      </el-select>
      </el-form-item>
      <el-form-item label="上传">
        <el-upload
            style="margin-top: 10px"
            class="upload-demo"
            action="https://sites.zzmeetluyao.com/api/luyao/taobao/upload"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :on-success="onsuccess"
            multiple
            :on-exceed="handleExceed"
            :file-list="fileList"
            :data="paramsData"
            :before-upload="beforeUpload"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传excel，大小不能超过50M
          </div>
        </el-upload>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
<script>
import $ from "jquery";

export default {
  methods: {
    beforeUpload(file){
      this.isLt2k = file.size / 1024  < 51200?'1':'0';        
	if(this.isLt2k==='0') {            
		this.$message({                
			message: '上传文件大小不能超过50M!',                
			type: 'error'            
		});        
	}        
	return this.isLt2k==='1'?true: false;
    },
    onsuccess(response, file, fileList) {
      if(response.status==200){
        this.$message('上传成功')
      }else{
        this.$message(response.message)
      }
      
    },
    
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },

    handleClick(row) {
      console.log(row);
    },
    getCustomerList() {
      
      this.$api.get(
          "/customer/list",
          null,
          {
            list: false
          },
          (successRes) => {
            this.customerOptions = successRes.data.rows;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
     
  },
  data() {
    return {
      customerOptions: [],
      customerId: null,
      typeOptions: [
         {
          id: 0,
          value: "选词助手",
          label: "dictionHelper",
        },
         {
          id: 1,
          value: "生意参谋",
          label: "categoryCompass",
        },
      ],
      typeid: "",
      fileList: [],
      value: "",
      
    };
  },
  mounted() {
    this.getCustomerList();
  },
  computed: {
    paramsData: function () {
      let params = {
        custId: this.customerId,
        tableName:this.typeid,
         
      }
      console.log(params);
      return params
    }
  },
};
</script>